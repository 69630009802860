import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"

/* Farms page, uses the Video > Rich Text > Stepped Navigation structure */

class Farms extends React.Component {
  componentDidMount() {
    // Inactivity()
  }
  
  render() {
    return(
      <Layout>
        <Helmet>
          <title>Meet MJN - Visit One Of Our Farms - Family</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -general -video">
            <div className="video-player-wrapper">
              <div className="youtube-player-wrapper">
                <iframe className="youtube-player" id="youtube-video" title="video" width="540" height="304" src="https://www.youtube.com/embed/KiZB95eGfK0?autoplay=1&controls=0&modestbranding=1&playsinline=1rel=0" allow="autoplay" frameBorder="0"></iframe>
              </div>
            </div>
          </section>

          <section className="rich-text -bg-white -with-stepped">
            <nav className="breadcrumb" role="navigation">
              <Link to="/event-experience" title="Event Experience"> 
                <i className="fas fa-arrow-left"></i>
                Event Experience
              </Link>
            </nav>
            <h2>Family Life on the Farm</h2>
            
            <p>Family farming here for my own family is one of I suppose really of team work it is one of people working together but it is also one where we take great responsibility in what we do because we know that we are tasked with that responsibility of using pasture based farming to produce great milk to produce great ingredients for those who are going to consume that we're very proud of it we take it seriously and it's also so important to us that we pass on this knowledge, these skills and these values to our children so that it will continue to survive right into the generations of the future.</p>
          </section>

          <ol className="stepped-navigation">
            <li className="stepped-navigation__item"><Link to="/event-experience/grazing"></Link></li>
            <li className="stepped-navigation__item -active"><span>Family</span></li>
            <li className="stepped-navigation__item"><Link to="/event-experience/milk"></Link></li>
          </ol>
        </main>
      </Layout>
    )
  }
}

export default Farms